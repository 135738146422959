import { Marker, InfoWindow } from "@react-google-maps/api"
import React from "react"
import { useAtom, useSetAtom } from "jotai"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { activeMarkerAtom, hoverMarkerAtom } from "../state/use-stories"
import { setCenterAtom } from "../state/use-map"

const MapMarker = (props) => {
  const [activeMarker, setActiveMarker] = useAtom(activeMarkerAtom)
  const [hoverMarker, setHoverMarker] = useAtom(hoverMarkerAtom)
  const setCenter = useSetAtom(setCenterAtom)

  return (
    <>
      <Marker
        position={{
          lat: props.location.lat,
          lng: props.location.lon,
        }}
        visible={true}
        // icon={{
        //   path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
        //   fillColor: "#12AED9",
        //   fillOpacity: 1.0,
        //   strokeWeight: 0,
        //   rotation: 0,
        //   scale: 1.5,
        // }}
        onClick={function (e) {
          setActiveMarker(props.id)
          setCenter({
            lat: props.location.lat,
            lng: props.location.lon,
          })
          e.domEvent.stopPropagation()
        }}
        onMouseOver={() => setHoverMarker(props.id)}
        icon={{
          path: "M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z  M 9, 10 a 3,3 0 1,1 6,0 a 3,3 0 1,1 -6,0",
          fillColor: pinColor(props.organization.pinColor),
          fillOpacity: 1.0,
          strokeColor: "#fff",
          strokeWeight: 2,
          rotation: 0,
          scale:
            hoverMarker === props.id || activeMarker === props.id ? 2 : 1.5,
          anchor: {
            x: 10,
            y: 20,
          },
        }}
      />
    </>
  )
}

function pinColor(color) {
  return color ? color : "#da2128"
}

export default MapMarker
