import React, { useState, useLayoutEffect } from "react"
import { GoogleMap, LoadScript } from "@react-google-maps/api"
import { activeMarkerAtom } from "../state/use-stories"
import { useSetAtom, useAtomValue } from "jotai"
import { centerAtom, setCenterAtom } from "../state/use-map"

const containerStyle = {
  /* minHeight: "400px",
  height: "clamp(300px, 75vh, 600px)", */
  height: "100%",
}

const initialCenter = {
  lat: 42.0,
  lng: -93.5,
  initial: true,
}

const styles = [
  {
    featureType: "all",
    elementType: "geometry.fill",
    stylers: [
      {
        weight: "2.00",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#414042",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#eceded",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#f6fcfe",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#cccccc",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 5,
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#828084",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#12aed9",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#12aed9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#070707",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
]

function Map(props) {
  const [zoom, setZoom] = useState(6)
  const setActiveMarker = useSetAtom(activeMarkerAtom)
  const center = useAtomValue(centerAtom)
  const setCenter = useSetAtom(setCenterAtom)

  const onLoad = () => {
    const width = window.innerWidth
    const height = window.innerHeight
    setTimeout(() => {
      if (width > 800 && height > 600) {
        setZoom(7.35)
      } else if (width > 600 && height > 400) {
        setZoom(6.5)
      } else {
        setZoom(6)
      }
      setCenter(initialCenter)
    }, 100)
  }

  return (
    <LoadScript googleMapsApiKey={process.env.GATSBY_GOOGLE_MAP_API_KEY}>
      <GoogleMap
        onLoad={onLoad}
        onClick={() => setActiveMarker(null)}
        options={{
          disableDefaultUI: true,
          mapTypeId: "terrain",
          styles,
        }}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        onZoomChanged={(e) => console.log(e)}
      >
        {props.children}
      </GoogleMap>
    </LoadScript>
  )
}

export default Map
