import React from "react"
import { useSetAtom } from "jotai"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { activeMarkerAtom } from "../state/use-stories"
import { DesktopMediaQuery } from "../utils/css-variables"
import * as Icon from "react-feather"
import { keyframes } from "@emotion/react"

const show = keyframes`
from { opacity: 0 }
to { opacity: 1 }`

const Badge = (props) => {
  const I = Icon[props.icon]

  return (
    <div
      css={{
        display: "inline-flex",
        alignItems: "center",
        backgroundColor: "#eceded",
        borderRadius: "1em",
        padding: "0.25em 1em",
        fontSize: "0.75em",
        fontWeight: 500,
        marginRight: "0.5em",
      }}
    >
      <I size="0.8em" css={{ margin: "-0 0.5em 0 0" }} />
      {props.children}
    </div>
  )
}

const StoryDetail = (props) => {
  const setActiveMarker = useSetAtom(activeMarkerAtom)

  return (
    <div css={{ opacity: 0, animation: `${show} .2s ease .3s forwards` }}>
      <div
        css={{
          "@media only screen and (min-width: 1020px)": {
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
          },
        }}
      >
        {props.profile?.publicUrl && !props.videoUrl && (
          <div
            css={{
              aspectRatio: "4 / 3",
              marginRight: "1em",
              "@media only screen and (min-width: 1020px)": {
                maxWidth: "250px",
              },
            }}
          >
            <img
              src={props.profile.publicUrl}
              width={props.profile.width}
              css={{ objectFit: "cover", width: "100%" }}
            />
          </div>
        )}

        <div css={{ width: "100%" }} >
          <h1
            css={(theme) => ({
              color: theme.headingColor,
              fontSize: "1.5rem",
              margin: "0 1.25em 0.5em 0",
            })}
          >
            {props.header}
          </h1>
          <div
            css={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "1em",
            }}
          >
            <h2
              css={{
                fontSize: "1rem",
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Icon.User size="0.8em" css={{ margin: "-0.25em 0.5em 0 0" }} />{" "}
              {props.name}
            </h2>
            <div>
              {props.language && (
                <Badge icon="Globe">{props.language.name}</Badge>
              )}
              {props.programs?.map((program) => (
                <Badge key={program.name} icon="Tag">
                  {program.name}
                </Badge>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
        }}
      >
        {props.videoUrl && (
          <iframe
            css={{
              aspectRatio: "16/9",
              border: 0,
            }}
            src={video_embed_url(props.videoUrl)}
            allowFullScreen={true}
          />
        )}

        {props.body?.raw &&
          documentToReactComponents(JSON.parse(props.body.raw))}

        <Icon.X
          onClick={() => setActiveMarker(null)}
          css={(theme) => ({
            margin: 0,
            fontSize: "1.65rem",
            color: theme.linkColor,
            cursor: "pointer",
            position: "absolute",
            top: "10px",
            right: "10px",
          })}
        />
      </div>
    </div>
  )
}

function youtube_embed_url(url) {
  return url.includes("watch?v=")
    ? url.replace("watch?v=", "embed/")
    : url.replace("youtu.be/", "youtube.com/embed/")
}

function facebook_embed_url(url) {
  if (url.includes("plugins/video.php")) {
    return url
  }
  return (
    "https://www.facebook.com/plugins/video.php?href=" + encodeURIComponent(url)
  )
}

function video_embed_url(url) {
  if (url.includes("youtu.be") || url.includes("youtube")) {
    return youtube_embed_url(url)
  } else if (url.includes("fb.watch") || url.includes("facebook")) {
    return facebook_embed_url(url)
  }
}

export default StoryDetail
