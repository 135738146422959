import { atom } from "jotai"

export const centerAtom = atom({
  lat: 42.0,
  lng: -93.5,
})

const getOffset = (initial) => {
  console.log(initial)
  const width = window.innerWidth

  if (width > 1000) {
    return {
      lat: 0,
      lng: initial ? -2 : -0.25,
    }
  }

  if (width > 800) {
    return {
      lat: 0,
      lng: initial ? -1 : -0.25,
    }
  }

  return {
    lat: initial ? -3 : -0.5,
    lng: 0,
  }
}

export const setCenterAtom = atom(null, (get, set, center) => {
  const offset = getOffset(center.initial)

  set(centerAtom, {
    lat: center.lat + offset.lat,
    lng: center.lng + offset.lng,
  })
})
