import React from "react"
import lightenDarkenColor from "../utils/lighten-or-darken"

const ButtonLink = (props) => (
  <button
    css={(theme) => ({
      color: theme.linkColor,
      background: "none",
      border: "none",
      display: "inline-flex",
      fontFamily: "Hind",
      fontSize: "0.9em",
      cursor: "pointer",
      alignItems: "center",
      padding: "0.5em 0",
      "&:hover": {
        color: lightenDarkenColor(theme.linkColor, 50),
      },
      fontWeight: 500,
    })}
    {...props}
  />
)

export default ButtonLink
