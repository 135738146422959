import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { atom, useAtomValue } from "jotai"
import lunr from "lunr"

export const programsAtom = atom([])
export const languagesAtom = atom([])
export const organizationsAtom = atom([])
export const searchAtom = atom("")
export const activeMarkerAtom = atom()
export const hoverMarkerAtom = atom()

export const useStories = () => {
  const { allContentfulStory } = useStaticQuery(graphql`
    query {
      allContentfulStory(sort: { order: DESC, fields: createdAt }) {
        edges {
          node {
            id
            header
            body {
              raw
            }
            location {
              lat
              lon
            }
            name
            videoUrl
            language {
              name
            }
            organization {
              name
            }
            programs {
              name
            }
            organization {
              pinColor
            }
            profile {
              publicUrl
              resize(aspectRatio: 1, height: 50) {
                width
                src
                height
              }
            }
            tags {
              name
            }
          }
        }
      }
    }
  `)

  const index = React.useMemo(
    () =>
      lunr(function () {
        this.ref("id")
        this.field("header", { boost: 20 })
        this.field("name")
        this.field("body")
        this.field("tags", { boost: 50 })
        this.field("programs")
        this.field("language")
        this.field("organization")

        allContentfulStory.edges?.forEach(({ node }) =>
          this.add({
            id: node.id,
            name: node.name,
            body: node.body?.raw,
            header: node.header,
            tags: node.tags?.map(({ name }) => name).join(" "),
            programs: node.programs?.map(({ name }) => name).join(" "),
            language: node.language?.name,
            organization: node.organization?.name,
          })
        )
      }),
    [allContentfulStory]
  )

  const programs = useAtomValue(programsAtom)
  const languages = useAtomValue(languagesAtom)
  const organizations = useAtomValue(organizationsAtom)
  const search = useAtomValue(searchAtom)

  const keywords = search.trim().replace(/\*/g, "").toLowerCase().split(/\s+/)

  const storiesFilteredBySearch = search
    ? index
        .query((q) => {
          keywords.forEach((word) => {
            q.term(word, { editDistance: word.length > 5 ? 1 : 0 })
            q.term(word, {
              wildcard:
                lunr.Query.wildcard.LEADING | lunr.Query.wildcard.TRAILING,
            })
          })
        })
        .map(({ ref }) =>
          allContentfulStory.edges.find(({ node }) => node.id === ref)
        )
    : allContentfulStory.edges

  const filteredStories = storiesFilteredBySearch.filter(({ node }) => {
    if (programs.length) {
      if (!node.programs?.some(({ name }) => programs.includes(name))) {
        return false
      }
    }

    if (languages.length) {
      if (!languages.includes(node.language.name)) {
        return false
      }
    }

    if (organizations.length) {
      if (!organizations.includes(node.organization.name)) {
        return false
      }
    }

    return true
  })

  return { filteredStories, allStories: allContentfulStory.edges }
}
