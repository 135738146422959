import React, { useState } from "react"
import { useAtom } from "jotai"
import { Filter } from "react-feather"
import {
  programsAtom,
  languagesAtom,
  organizationsAtom,
  searchAtom,
} from "../state/use-stories"
import FilterGroup from "./filter-group"
import ButtonLink from "./button-link"

const SearchAndFilter = (props) => {
  const [show, setShow] = useState(false)
  const [search, setSearch] = useAtom(searchAtom)
  const [programs, setPrograms] = useAtom(programsAtom)
  const [languages, setLanguages] = useAtom(languagesAtom)
  const [organizations, setOrganizations] = useAtom(organizationsAtom)
  const allTags = [...programs, ...languages, ...organizations]
  return (
    <form role="search" onSubmit={(e) => e.preventDefault()}>
      <label
        htmlFor="search"
        css={(theme) => ({
          color: theme.headerColor,
          fontSize: "0.8em",
          fontWeight: 600,
        })}
      >
        Search
      </label>
      <input
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        id="search"
        type="search"
        css={{
          padding: "0.5em 1em",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          border: "1px solid #00587e",
          boxShadow: "0 0 6px rgba(0, 0, 0, 0.1)",
          borderRadius: "0.25em",
          display: "block",
          width: "100%",
          fontSize: "1em",
        }}
      />
      <div
        css={{
          display: show ? "block" : "flex",
          alignItems: "center",
          flexWrap: "no-wrap",
        }}
      >
        <ButtonLink type="button" onClick={() => setShow((s) => !s)}>
          <Filter size="0.9em" />{" "}
          <span css={{ marginLeft: "0.25em" }}>Filters</span>
        </ButtonLink>
        {show ? (
          <div>
            <FilterGroup
                name="Organizations"
                contentType={props.allContentfulOrganization}
                items={organizations}
                setItems={setOrganizations}
                colorField="pinColor"
            />
            <FilterGroup
              name="Categories"
              contentType={props.allContentfulCategory}
              items={programs}
              setItems={setPrograms}
            />
            <FilterGroup
              name="Languages"
              contentType={props.allContentfulLanguage}
              items={languages}
              setItems={setLanguages}
            />
          </div>
        ) : (
          <span
            css={{
              fontSize: "0.675em",
              marginLeft: "0.5em",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {allTags.length > 0 && (
              <svg width="8" height="8">
                <circle cx="4" cy="4" r="4" fill="#000" />
              </svg>
            )}
            {allTags.map((tag, i) => (
              <span key={i} css={{ margin: "0 0.35em" }}>
                {tag}
              </span>
            ))}
          </span>
        )}
      </div>
    </form>
  )
}

export default SearchAndFilter
