import React from "react"
import {
  activeMarkerAtom,
  hoverMarkerAtom,
  useStories,
} from "../state/use-stories"
import { User, MapPin } from "react-feather"
import { useAtom, useSetAtom } from "jotai"
import { utils } from "./utils"
import { DesktopMediaQuery } from "../utils/css-variables"
import { setCenterAtom } from "../state/use-map"

const getInitials = (name) => {
  const [f, l] = name.split(" ")

  return `${f[0]}${l?.[0] || f[1]}`.toUpperCase()
}

const StoryList = () => {
  const { filteredStories } = useStories()
  const [activeMarker, setActiveMarker] = useAtom(activeMarkerAtom)
  const setHoverMarker = useSetAtom(hoverMarkerAtom)
  const setCenter = useSetAtom(setCenterAtom)

  return (
    <>
      <div
        css={{
          //overflow: "auto",
          margin: "1rem -1rem",
          borderTop: "1px solid #eceded",
          borderBottom: "1px solid #eceded",
          [DesktopMediaQuery]: {
            overflow: "auto",
            flex: 1,
          },
        }}
      >
        {filteredStories.length === 0 ? (
          <div
            css={{
              borderBottom: "1px solid white",
              borderTop: "1px solid #eceded",
              padding: "1em",
              "&:hover": {
                backgroundColor: "rgba(0, 120, 255, 0.05)",
              },
            }}
          >
            No stories found that match your search and filters
          </div>
        ) : (
          filteredStories.slice(0, 5).map((edge, i) => (
            <div
              role="button"
              tabIndex={0}
              onClick={() => {
                setActiveMarker(edge.node.id)
                setCenter({
                  lat: edge.node.location.lat,
                  lng: edge.node.location.lon,
                })
              }}
              onKeyDown={(e) =>
                e.key === "32" ||
                (e.key === "13" && setActiveMarker(edge.node.id))
              }
              onMouseOver={() => {
                setHoverMarker(edge.node.id)
              }}
              onMouseOut={() => {
                setHoverMarker(null)
              }}
              key={i}
              css={{
                cursor: "pointer",
                borderBottom: "1px solid white",
                borderTop: "1px solid #eceded",
                backgroundColor:
                  activeMarker == edge.node.id ? "rgba(0, 120, 255, 0.05)" : "",
                padding: "1em",
                "&:hover": {
                  backgroundColor: "rgba(0, 120, 255, 0.05)",
                },
              }}
            >
              <div css={{ display: "flex", alignItems: "center" }}>
                <div css={{ marginRight: "1em" }}>
                  {edge.node.profile ? (
                    <img
                      src={edge.node.profile.resize.src}
                      alt=""
                      width="50"
                      height="50"
                      css={{ borderRadius: "50%", border: "4px solid #fff" }}
                    />
                  ) : edge.node.videoUrl?.includes("youtu.be") ||
                    edge.node.videoUrl?.includes("youtube") ? (
                    <img
                      src={`https://img.youtube.com/vi/${
                        edge.node.videoUrl.includes("youtu.be")
                          ? edge.node.videoUrl.split("youtu.be/")[1]
                          : edge.node.videoUrl.split("v=")[1].split("&")[0]
                      }/0.jpg`}
                      alt=""
                      width="50"
                      height="50"
                      css={{ borderRadius: "50%" }}
                    />
                  ) : (
                    <div
                      css={(theme) => ({
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        backgroundColor: theme.iconColor,
                        color: "#fff",
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "1.5em",
                        lineHeight: "1",
                        fontWeight: 500,
                        border: "4px solid #fff",
                      })}
                    >
                      {getInitials(edge.node.name)}
                    </div>
                  )}
                </div>
                <div css={{ flex: 1, margin: "0 0.25em" }}>
                  <h2
                    css={{
                      fontSize: "1em",
                      lineHeight: "1.2",
                      margin: "0 0 0.25em",
                      fontWeight: 300,
                    }}
                  >
                    {edge.node.header}
                  </h2>
                  <div
                    css={{
                      fontWeight: 500,
                      color: "#828084",
                      display: "inline-flex",
                      alignItems: "center",
                      fontSize: "0.9em",
                    }}
                  >
                    <User size="1em" color="#999b9e" />
                    <span css={{ marginLeft: "0.25em" }}>{edge.node.name}</span>
                  </div>
                </div>
                <div
                  css={(theme) => ({
                    color: utils(edge.node.organization.pinColor),
                  })}
                >
                  <MapPin />
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <small>
        Showing {filteredStories.length > 5 ? "5" : filteredStories.length} of{" "}
        {filteredStories.length} stories
      </small>
    </>
  )
}

export default StoryList
