import React from "react"

const Checkbox = (props) => (
  <label
    css={(theme) => ({
      display: "inline-block",
      fontSize: "0.8em",
      border: `1px solid ${props.color}`,
      borderRadius: "1em",
      padding: "0.125em 1em",
      margin: "0.25em",
      background: props.items.includes(props.node.name) ? props.color : "#ffffff",
      color: props.items.includes(props.node.name) ? "#ffffff" : props.color,
      cursor: "pointer",
    })}
  >
    <input
      type="checkbox"
      id={props.node.id}
      checked={props.items.includes(props.node.name)}
      onChange={(e) =>
        props.setItems((p) => {
          if (e.target.checked) {
            return [...p, props.node.name]
          } else {
            return p.filter((x) => x !== props.node.name)
          }
        })
      }
      css={{ fontSize: "1.25em", display: "none" }}
    />
    {props.node.name}
  </label>
)

export default Checkbox
