import React from "react"
import ButtonLink from "./button-link"
import Checkbox from "./checkbox"

const FilterGroup = (props) => (
  <div>
    <fieldset css={{ border: "none", padding: "0", marginBottom: "1em" }}>
      <legend
        css={{
          fontSize: "0.9em",
          fontWeight: 600,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        {props.name}{" "}
        <ButtonLink type="button" onClick={() => props.setItems([])}>
          Clear filter
        </ButtonLink>
      </legend>
      {props.contentType.edges.map((edge, i) => (
        <Checkbox
          node={edge.node}
          key={i}
          items={props.items}
          setItems={props.setItems}
          color={props.colorField ? edge.node[props.colorField] : "#414042" }
        />
      ))}
    </fieldset>
  </div>
)

export default FilterGroup
