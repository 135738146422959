import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Map from "../components/map"
import MapMarker from "../components/map-marker"
import StoryDetail from "../components/story-detail"
import StoryList from "../components/story-list"
import { activeMarkerAtom, useStories } from "../state/use-stories"
import { useAtomValue } from "jotai"
import SearchAndFilter from "../components/search-and-filter"
import { Maximize2, Minimize2 } from "react-feather"
import { DesktopMediaQuery } from "../utils/css-variables"
import { keyframes } from "@emotion/react"

const grow = keyframes`
from { max-width: 0 }
to { max-width: 100vw }`

export default function Homepage(props) {
  const {
    allContentfulTheme,
    allContentfulCategory,
    allContentfulLanguage,
    allContentfulOrganization,
  } = props.data

  const themeContent = allContentfulTheme.edges[0]?.node || {}
  const { filteredStories, allStories } = useStories()
  const activeMarker = useAtomValue(activeMarkerAtom)
  const [activeStoryList, setActiveStoryList] = React.useState(true)
  const MinMaxIcon = activeStoryList ? Minimize2 : Maximize2

  const cardRef = React.useRef(null)

  useEffect(() => {
    if (cardRef.current && activeStoryList) {
      setTimeout(cardRef.current.scrollTo(0, 0), 200)
    }
  }, [activeMarker, cardRef, activeStoryList])

  useEffect(() => {
    if (activeMarker) {
      setActiveStoryList(true)
    }
  }, [activeMarker])

  return (
    <Layout title={themeContent.siteTitle}>
      <div
        css={{
          position: "relative",
          height: "calc(100vh - 60px)",
        }}
      >
        <div
          ref={cardRef}
          css={(theme) => ({
            position: "absolute",
            top: "auto",
            right: "1.5rem",
            bottom: "1.5rem",
            left: "1.5rem",
            inset: "auto 1.5rem 1.5rem 1.5rem",
            height: activeMarker ? "80vh" : activeStoryList ? "40vh" : "3rem",
            width: activeStoryList ? "auto" : "3rem",
            zIndex: 1,
            background: activeStoryList
              ? "rgba(255, 255, 255, .7)"
              : theme.footerColor,
            backdropFilter: "blur(4px)",
            borderRadius: activeStoryList ? "0.5rem" : "50%",
            boxShadow: "0 8px 32px 0 rgba( 0, 0, 0, 0.1 )",
            overflow: activeMarker || activeStoryList ? "auto" : "hidden",
            [DesktopMediaQuery]: {
              // background: "rgba(255, 255, 255, .7)",
              top: "auto",
              right: "1.5rem",
              bottom: activeStoryList ? "1.5rem" : "auto",
              left: "1.5rem",
              inset: activeStoryList
                ? "2rem auto 2rem 2rem"
                : "2rem auto auto 2rem",
              maxWidth: "1200px",
              height: activeStoryList ? "auto" : "3em",
              width: activeStoryList ? "auto" : "3em",
            },
          })}
        >
          <div css={{ display: "flex" }}>
            {!activeMarker && (
              <MinMaxIcon
                size="0.75em"
                role="button"
                tabIndex="0"
                onClick={() => setActiveStoryList(!activeStoryList)}
                onKeyDown={() => setActiveStoryList(!activeStoryList)}
                css={(theme) => ({
                  margin: 0,
                  fontSize: "1.65rem",
                  color: activeStoryList ? theme.linkColor : "#fff",
                  cursor: "pointer",
                  position: "absolute",
                  top: activeStoryList ? "5px" : "14px",
                  right: activeStoryList ? "10px" : "14px",
                  zIndex: 1,
                  [DesktopMediaQuery]: {
                    top: activeStoryList ? "5px" : "16px",
                    right: activeStoryList ? "10px" : "16px",
                  },
                })}
              />
            )}
            <div
              css={{
                maxWidth: "100%",
                padding: "1rem",
                width: "100%",
                display: activeMarker || !activeStoryList ? "none" : "flex",
                flexDirection: "column",

                [DesktopMediaQuery]: {
                  display: !activeStoryList ? "none" : "flex",
                  width: "400px",
                  flex: "0 0 auto",
                  height: "100%",
                },
              }}
            >
              <div
                css={{
                  display: "flex",
                  "@media only screen and (max-width: 500px)": {
                    flexDirection: "column",
                  },
                }}
              >
                <h1
                  css={(theme) => ({
                    color: theme.linkColor,
                    margin: 0,
                    fontSize: "1.65rem",
                  })}
                >
                  {themeContent.siteTitle}
                </h1>
                <div
                  css={(theme) => ({
                    flex: "1",
                    borderBottom: `5px solid ${theme.accentColor}`,
                    position: "relative",
                    top: "-11px",
                    marginLeft: "6px",
                    "@media only screen and (max-width: 500px)": {
                      top: "0",
                      marginLeft: "0",
                    },
                  })}
                />
              </div>

              {activeStoryList && (
                <>
                  <p css={{ fontSize: "0.875rem", lineHeight: "1.5" }}>
                    {themeContent.siteDescription?.internal?.content}
                  </p>
                  <SearchAndFilter
                    allContentfulCategory={allContentfulCategory}
                    allContentfulLanguage={allContentfulLanguage}
                    allContentfulOrganization={allContentfulOrganization}
                  />
                  <StoryList />
                </>
              )}
            </div>

            {activeMarker && (
              <div
                css={{
                  borderLeft: "1px solid #eceded",
                  padding: "1rem",
                  [DesktopMediaQuery]: {
                    maxWidth: "0%",
                    animation: `${grow} .3s ease forwards`,
                  },
                }}
              >
                <StoryDetail
                  {...allStories.find((edge) => edge.node.id === activeMarker)
                    ?.node}
                />
              </div>
            )}
          </div>
        </div>
        <span id="map">
          <Map>
            {filteredStories.map((edge, i) => (
              <MapMarker {...edge.node} key={edge.node.id} />
            ))}
          </Map>
        </span>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulTheme {
      edges {
        node {
          siteTitle
          siteDescription {
            internal {
              content
            }
          }
        }
      }
    }
    allContentfulCategory(sort: { order: ASC, fields: name }) {
      nodes {
        id
        name
      }
      edges {
        node {
          id
          name
        }
      }
    }
    allContentfulLanguage(sort: { order: ASC, fields: name }) {
      nodes {
        id
        name
      }
      edges {
        node {
          id
          name
        }
      }
    }
    allContentfulTag(sort: { order: ASC, fields: name }) {
      nodes {
        id
        name
      }
      edges {
        node {
          id
          name
        }
      }
    }
    allContentfulOrganization {
      edges {
        node {
          id
          name
          pinColor
        }
      }
    }
  }
`
